@import 'assets/_constants.scss';

#enquiry-form-page {
  .fields {
    flex-direction: row;
    flex-wrap: wrap;

    h3 {
      width: 100%;
    }

    > * {
      width: calc(50% - #{$size-lg});

      @media screen and (max-width: $screen-lg) {
        width: 100%;
      }
    }

    > *:nth-child(even) {
      margin-right: calc(#{$size-lg} + #{$size-lg});

      @media screen and (max-width: $screen-lg) {
        margin-right: 0;
      }
    }
  }
}
