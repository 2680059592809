/** Regular font */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/Lato-Regular.ttf') format('truetype');
}

/** Italic font */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: normal;
  src: url('./fonts/Lato-Italic.ttf') format('truetype');
}

/** Bold font */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/Lato-Bold.ttf') format('truetype');
}

/** Bold-Italic font */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: bold;
  src: url('./fonts/Lato-BoldItalic.ttf') format('truetype');
}

/** Light font */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/Lato-Light.ttf') format('truetype');
}

/** Light-Italic font */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 200;
  src: url('./fonts/Lato-LightItalic.ttf') format('truetype');
}
