@import 'assets/_constants';
@import 'assets/_mixins';

.funus-select-input {
  display: flex;

  .funus-select {
    flex: 2;
    margin-right: $size-lg;
  }
  .funus-input {
    flex: 5;
  }
}
