@import 'assets/_constants';
@import 'assets/_mixins';

.f-budgets-table{
  .funus-table.actions-table table tbody tr td.action-column > div .funus-button,
  .funus-table.actions-table table tbody tr td.action-column > div a,
  .funus-table.actions-table table tbody tr td.action-column > div .funus-button:hover {
        color: #a29800;
    }
  }
