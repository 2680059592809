@import 'assets/_constants';
@import 'assets/_mixins';

.funus-select-input {
  display: flex;

  .funus-select {
    width: 1;
    margin-right: $size-lg;
  }
  .funus-input {
    flex: 3;
  }
}
