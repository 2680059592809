.funus-button.q-confirm-button {
  height: 6vh;
  min-width: 25vw;
  margin-top: 1vh;
  &.q-confirm-button-with-right-sibling {
    border-radius: 0.25rem 0 0 0.25rem;
    margin-right: 0.1rem;
  }
}

.funus-button.q-confirm-button-summary {
  height: 6vh;
  margin-top: 1vh;
  &.q-confirm-button-with-left-sibling {
    border-radius: 0 0.25rem 0.25rem 0;
  }
}