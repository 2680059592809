@import 'assets/_constants';
@import 'assets/_mixins';

.transfer-form {
  .transfer-buttons {
    @include flex-start;
    > *:not(:last-child) {
      margin-right: $size-lg;
    }
  }
}
