@import 'assets/_constants';
@import 'assets/_mixins';

.input-qr {
  .funus-input:not(.checkbox):not(.radio) > div > div:not(.bottom-line) .input-suffix.funus-button {
    width: $size-xl;

    .qr-icon > div {
      @include flex-center;
      svg,
      svg * {
        color: $white;
        stroke: $white;
      }
    }
  }
}
