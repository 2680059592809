@import 'assets/_constants';
@import 'assets/_mixins';

.record-landing {
  padding: $size-xl;

  > div {
    display: flex;

    > .funus-box {
      flex: 1;
      max-width: none;
      overflow: hidden;

      &.record-left-box {
        margin-right: $size-lg;
        flex: 3;
      }
      &.record-right-box {
        margin-left: $size-lg;
        flex: 4;
      }
    }

    @media screen and (max-width: $screen-xl) {
      flex-direction: column;

      > .funus-box {
        flex: 1;
        max-width: 100%;

        &.record-left-box {
          margin-right: 0;
          margin-bottom: $size-xl;
        }
        &.record-right-box {
          margin-left: 0;
        }
      }
    }
  }
}
