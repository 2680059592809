@import 'assets/_constants';
@import 'assets/_mixins';

.client-expandable-box {
  padding-top: $size-xl;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    margin-bottom: $size-md;
    width: 100%;

    &:nth-child(odd) {
      margin-right: $size-md;
    }
    &:nth-child(even) {
      margin-left: $size-md;
    }

    &:not(.declarants-buttons):not(.funus-table):not(.full-width) {
      width: calc(50% - #{$size-md});
    }
  }

  .declarants-buttons {
    display: flex;
    margin: $size-lg 0;

    button {
      margin-right: $size-lg;
    }
  }

  .funus-table {
    border: solid 1px $primary;
    margin: 0;
  }
}
