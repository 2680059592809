@import 'assets/_constants';
@import 'assets/_mixins';

.funus-table {
  display: flex;
  flex-direction: column;

  > div:not(.pagination) {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;
  }
  table {
    overflow: visible;
    width: 100%;

    thead,
    tbody {
      tr {
        th,
        td {
          padding: 0 $size-lg;
          font-size: $font-md;
          white-space: nowrap;
          text-overflow: ellipsis;
          // max-width: calc(100vw / 4);
          height: rem(54);

          &:not(.collapse-text) {
            overflow: hidden;
          }

          &.hidden {
            display: none;
          }

          &.collapse-text {
            .funus-tooltip {
              .tooltip-children {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: rem(200);
              }
            }
          }
        }
      }
    }
    thead {
      border-bottom: 1px solid $primary;
      tr {
        th {
          color: $primary-dark;
          font-weight: bold;

          .sort-header {
            white-space: nowrap;
            svg {
              @include default-transition;
              height: $size-lg;

              &.inverted-icon {
                transform: rotate(180deg);
              }

              &.not-sorted {
                height: 0;
              }
            }
          }

          .filter-input {
            font-weight: 400;

            &:not(.focused):not(.hovered):not(.filled) {
              label {
                padding-left: $size-md;
              }
            }

            &:not(.focused):not(.hovered) {
              > div {
                background-color: $gray-100;
              }
            }
            &.focused,
            &.hovered {
              > div {
                @include primary-shadow;
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid $gray-300;
        }
      }
    }
    tfoot {
      border-top: 1px solid $gray-300;
      width: 100%;
      tr {
        td {
          > .pagination {
            display: flex;
            align-items: center;
            padding-top: $size-lg;

            > *:not(:last-child) {
              margin-right: $size-md;
            }

            > .funus-button {
              padding: $size-xs $size-sm;
            }

            @media screen and (max-width: $screen-md) {
              flex-direction: column;
              > *:not(:last-child) {
                margin-right: 0;
                margin-top: $size-md;
              }
            }

            @media screen and (max-width: $screen-sm) {
              flex-direction: row;
              > *:not(:last-child) {
                margin-right: $size-sm;
              }
            }
          }
        }
      }
    }
  }

  &.actions-table {
    position: relative;
    table {
      thead {
        tr {
          th.actions-header {
            display: none;
          }
        }
      }
      tbody {
        tr {
          @include default-transition;

          td.action-column {
            // padding: 0 $size-xl;
            > div {
              // margin-top: rem(-26);
              height: rem(52);
              display: flex;
              justify-content: flex-end;
              // position: absolute;
              right: 1px;

              .tooltip-children {
                height: rem(52);
                display: flex;
              }

              .funus-button,
              a,
              .funus-button:hover {
                background: transparent;
                border-color: transparent;
                padding: 0 $size-md;
              }
            }
          }

          &:not(.no-items):hover {
            background-color: rgba($primary, 0.25);

            td.action-column {
              > div {
                padding-left: $size-xl;
                background: linear-gradient(90deg, rgba(#f5f1cc, 0) 0%, #f5f1cc 25%, #f5f1cc 100%);
              }

              .funus-button,
              a {
                color: $primary-dark;
                pointer-events: all;
              }
            }
          }

          &:not(.no-items) {

            td.action-column {
              > div {
                padding-left: $size-xl;
              }

              .funus-button,
              a {
                color: $primary-dark;
                pointer-events: all;
              }
            }
          }

        }
      }

      @media screen and (max-width: $screen-xl) {
        thead {
          tr {
            th.actions-header {
              display: block;
            }
          }
        }
        tbody {
          tr {
            td.action-column {
              > div {
                padding-left: $size-xl;
                background: linear-gradient(
                  90deg,
                  rgba($white, 0) 0%,
                  rgba($white, 1) 20%,
                  rgba($white, 1) 100%
                );
                @include default-transition;

                .funus-button,
                a {
                  color: $primary;
                  &:hover {
                    color: $primary-dark;
                  }
                }
              }
            }

            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
