@import 'assets/_constants';
@import 'assets/_mixins';

.funus-radio {
  .radio-placeholder {
    color: $gray-700;
    font-size: $font-md;
  }

  > *:not(:last-child) {
    margin-bottom: $size-md;
  }

  &.error {
    .radio-placeholder {
      color: $error;
    }

    .funus-input.radio {
      label {
        color: $error;
      }
      > div > div .checkmark {
        border-color: $error;
      }
    }
  }

  > span {
    color: $error;
    font-size: $font-sm;
    @include default-transition;
    width: 100%;

    &.hidden {
      font-size: 0;
    }
  }
}
