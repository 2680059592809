@import 'assets/_constants';
@import 'assets/_mixins';

.two-boxes-page {
  padding: $size-xl;

  > div {
    display: flex;

    > .funus-box {
      flex: 1;
      max-width: calc(50% - #{$size-lg});
      overflow: hidden;

      &.left-box {
        margin-right: $size-lg;
      }
      &.right-box {
        margin-left: $size-lg;
      }
    }

    @media screen and (max-width: $screen-xl) {
      flex-direction: column;

      > .funus-box {
        flex: 1;
        max-width: 100%;

        &.left-box {
          margin-right: 0;
          margin-bottom: $size-xl;
        }
        &.right-box {
          margin-left: 0;
        }
      }
    }
  }

  &.one-box {
    > div {
      > .funus-box {
        max-width: calc(100% - #{$size-lg});
      }
    }
  }
}
