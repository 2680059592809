@import 'assets/_constants';
@import 'assets/_mixins';

.work-order-card {
  @include flex-column;
  @include default-transition;
  background-color: $white;
  border: 1px solid $gray-500;
  border-left-width: $size-md;

  > div:not(.loader) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $gray-800;

    &:first-child {
      margin-bottom: $size-lg;
      padding: $size-lg;
      border-bottom: 1px solid $gray-500;
    }

    .icon-more-actions {
      margin-right: -1rem;

      > div > button {
        padding: 0px;

        svg {
          color: $gray-500;
        }
      }
    }

    .icon-add-user {
      display: flex;
      justify-content: flex-end;
      margin-left: $size-md;

      > button,
      button.primary:focus,
      button.primary:hover {
        padding: 0px;
        background-color: transparent;
      }
    }

    svg,
    svg * {
      stroke-width: 1;
    }

    .icon-in-right {
      cursor: pointer;
      margin-right: 0px;
    }

    .order-type,
    .order-record {
      font-size: $font-sm;
    }
    .order-type {
      color: $gray-700;
      margin-right: calc(#{$size-xxl} + #{$size-xl});
      white-space: nowrap;

      @media screen and (max-width: $screen-xl) {
        margin-right: #{$size-xl};
      }
      @media screen and (max-width: $screen-lg) {
        margin-right: $size-lg;
      }
      svg {
        width: calc(#{$size-xl} + #{$size-sm});
        height: calc(#{$size-xl} + #{$size-sm});
        margin-bottom: $size-sm;
        margin-top: -$size-sm;
      }
    }
    .order-record {
      @include flex-center;
      font-size: $font-md;

      h6 {
        font-size: $font-md;
      }
      > svg {
        margin-right: $size-lg;
        width: calc(#{$size-xl} + #{$size-sm} + #{$size-md});
      }
    }
    .order-comment {
      font-weight: bold;
    }

    .order-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0px $size-lg;
      margin-bottom: $size-md;

      > div > svg {
        margin-right: $size-md;
        width: calc(#{$size-xl} + #{$size-sm} + #{$size-lg});
      }
    }

    .order-date {
      margin-right: $size-xl;
      font-size: $font-sm;
      white-space: nowrap;
      @media screen and (max-width: $screen-xl) {
        white-space: pre-wrap;
        margin-right: $size-md;
      }
    }

    .order-more-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: $black;
      border-radius: 0px;
      cursor: pointer;
      z-index: 1;

      &:not(.disabled):hover,
      &:not(.disabled):focus {
        color: $black;
      }

      &.urgent,
      &.urgent:hover,
      &.urgent:focus {
        background-color: rgba($secondary-light, 0.3);
      }

      &.normal,
      &.normal:hover,
      &.normal:focus {
        background-color: rgba($success, 0.3);
      }

      &.high,
      &.high:hover,
      &.high:focus {
        background-color: rgba($accent-blue, 0.3);
      }

      &.not-assigned,
      &.not-assigned:hover,
      &.not-assigned:focus {
        background-color: rgba($gray-500, 0.3);
      }

      .chevron-down {
        @include default-transition;
      }

      .rotate {
        transform: rotate(180deg);
      }
    }
  }

  &.urgent {
    background-color: rgba($secondary-light, 0.1);
    border-left-color: $secondary-light;

    > div {
      &:first-child {
        border-bottom-color: $secondary-light;
      }

      svg,
      svg * {
        color: $secondary-light;
        stroke: $secondary-light;
      }

      .hand {
        svg,
        svg * {
          fill: $secondary-light;
          stroke-width: 0;
        }
      }
      .fill {
        svg,
        svg * {
          fill: transparent;
          stroke-width: 3;
        }
      }

      .order-type,
      .order-date {
        color: $secondary-light;
      }
    }
  }

  &.normal {
    background-color: rgba($success, 0.1);
    border-left-color: $success;

    > div {
      &:first-child {
        border-bottom-color: $success;
      }

      svg,
      svg * {
        color: $success;
        stroke: $success;
      }

      .order-type,
      .order-date {
        color: $success;
      }
    }

    .hand {
      svg,
      svg * {
        fill: $success;
        stroke-width: 0;
      }
    }
    .fill {
      svg,
      svg * {
        fill: transparent;
        stroke-width: 3;
      }
    }
  }

  &.high {
    background-color: rgba($accent-blue, 0.1);
    border-left-color: $accent-blue;

    > div {
      &:first-child {
        border-bottom-color: $accent-blue;
      }

      svg,
      svg * {
        color: $accent-blue;
        stroke: $accent-blue;
      }

      .hand {
        svg,
        svg * {
          fill: $accent-blue;
          stroke-width: 0;
        }
      }
      .fill {
        svg,
        svg * {
          fill: transparent;
          stroke-width: 3;
        }
      }

      .order-type,
      .order-date {
        color: $accent-blue;
      }
    }
  }

  &.not-assigned {
    background-color: rgba($gray-500, 0.1);
    border-left-color: $gray-500;

    > div {
      &:first-child {
        border-bottom-color: $gray-500;
      }

      svg,
      svg * {
        color: $gray-700;
        stroke: $gray-700;
      }

      .hand {
        svg,
        svg * {
          fill: $gray-700;
          stroke-width: 0;
        }
      }
      .fill {
        svg,
        svg * {
          fill: transparent;
          stroke-width: 3;
        }
      }

      .order-type,
      .order-date {
        color: $gray-700;
      }
    }
  }

  .work-order-details {
    @include default-transition;
    max-height: rem(300);
    width: 100%;
    min-height: $size-xxl;
    padding: $size-md;
    overflow: auto;
    position: relative;

    .loader > div {
      height: $size-xl;
      width: $size-lg;

      img {
        height: $size-xl;
      }
    }

    .no-details {
      @include flex-center;
      @include fullscreen;
    }

    &.urgent,
    &.urgent:hover,
    &.urgent:focus {
      background-color: rgba($secondary-light, 0.2);
    }

    &.normal,
    &.normal:hover,
    &.normal:focus {
      background-color: rgba($success, 0.2);
    }

    &.high,
    &.high:hover,
    &.high:focus {
      background-color: rgba($accent-blue, 0.2);
    }

    &.not-assigned,
    &.not-assigned:hover,
    &.not-assigned:focus {
      background-color: rgba($gray-500, 0.2);
    }

    table {
      border-color: transparent;
      margin-bottom: 0rem;
      th,
      td {
        padding: $size-xs $size-sm;
      }
    }
  }

  .hide-details {
    opacity: 0;
    max-height: 0px;
    min-height: 0;
    padding: 0px $size-md;
    z-index: -1;
  }

  &.light-version {
    min-height: rem(100);
    > div:not(.loader) {
      &:first-child {
        margin-bottom: $size-md;
        padding: $size-md $size-lg;

        .order-record > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .order-record {
        overflow: hidden;
      }
    }
    .order-info {
      > div {
        overflow: hidden;
        > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
.workorder-card-modal .modal-dialog {
  width: 90vw;
  max-width: rem(400);

  form {
    > .funus-button {
      margin-top: $size-lg;
      margin-bottom: -$size-lg;
    }
    > .funus-select-button,
    .funus-input.checkbox {
      margin-top: $size-lg;
    }
    .funus-input {
      z-index: 1;
    }
    .select-options {
      z-index: 2;
    }
    legend {
      padding: $size-xs $size-md;
      margin: $size-lg 0 0 0;
      font-size: $font-lg;
      color: $gray-700;
      background-color: rgba($primary, 0.5);
    }
  }
}
