@import 'assets/_constants.scss';
@import 'assets/_mixins.scss';

.record-top-info {
  .expedient-button {
    position: absolute;
    right: $size-lg;
    top: calc(#{$size-lg} + #{$size-md});
  }

  > div {
    @include flex-column-center;

    span {
      color: $gray-700;
      font-size: $font-lg;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &.field-header {
        color: $primary-dark;
        font-size: $font-md;
      }
    }
    &.record-number {
      .funus-button {
        padding: 0;
        text-decoration: underline;
      }
    }
  }
}
