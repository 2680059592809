@import 'assets/_constants';
@import 'assets/_mixins';

.budget-summary-header {
  border-color: $primary;
  border-width: 0 0 2px 0;

  .budget-summary-header-code {
    text-align: left;
  }
  .budget-summary-header-description {
    text-align: left;
  }

  .budget-summary-header-amount {
    text-align: right;
  }

  .budget-summary-header-price{
    text-align: right;
    width: 12rem;
  }
}