@import 'assets/_constants';
.f-affected-questions{
  .funus-button,
  .funus-button:not(.disabled):hover {
    background-color: transparent;
    color: $primary-dark;
    border: 0;
    height: 1.5rem;
    width: 2rem;
  }
}