@import 'assets/_constants';
@import 'assets/_mixins';

.ceremony-form {
  .post-ceremony-buttons {
    @include flex-start;

    > *:not(:last-child) {
      margin-right: $size-lg;
    }
  }
}
