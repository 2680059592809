@import 'assets/_constants.scss';
@import 'assets/_mixins.scss';

#record-form-page {
  .services-button {
    position: absolute;
    right: $size-lg;
    top: calc(#{$size-lg} + #{$size-md});
  }

  .step-fields {
    .info-field {
      color: $gray-700;
      font-weight: 100;
      strong {
        color: $gray-900;
      }
    }
    .funus-table {
      border: 1px solid rgba($primary, 0.25);
      background-color: $white;
    }

    .declarants-buttons {
      @include flex-start;

      > *:not(:last-child) {
        margin-right: $size-lg;
      }
    }

    .funus-table {
      width: 100%;
    }
  }

  .form-generator {
    form {
      legend {
        margin-left: (-$size-lg);
        margin-right: (-$size-lg);
      }

      fieldset {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        > div {
          width: calc(50% - #{$size-lg});
          height: fit-content;

          @media screen and (max-width: $screen-lg) {
            width: 100%;
          }
        }
      }
    }
  }
}
