@import 'assets/_constants';
@import 'assets/_mixins';

#user-form-page {
  &.error-page {
    @include flex-center;
  }

  .fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    h3 {
      width: 100%;
    }

    > div:not(.image-input) {
      width: calc(50% - #{$size-lg});
      margin-right: $size-lg;

      @media screen and (max-width: $screen-lg) {
        width: 100%;
      }
    }

    > .image-input {
      width: calc(100% - #{$size-lg});
      border: rem(1) solid rgba($primary, 0.25);
      padding: $size-lg 0 0 $size-lg;
    }
  }
}
