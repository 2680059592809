@import 'assets/_constants';
@import 'assets/_mixins';

.funus-topbar {
  background-color: $white;
  height: $topbar-height;
  display: flex;
  color: $gray-700;
  z-index: 1000;
  @include basic-shadow;

  > *:not(.expand-button) {
    height: 100%;
  }

  .funus-topbar-collapsable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: calc(100vw - #{$sidebar-width});
    width: 100%;
    z-index: 1001;
    background-color: $white;

    .topbar-left,
    .topbar-right {
      display: flex;
    }

    .topbar-left {
      font-size: $font-xl;
      padding: $size-xl;
      color: $gray-800;
      font-weight: bold;
    }

    .topbar-right {
      .user-info {
        height: $topbar-height;

        > .funus-button {
          height: 100%;
          padding: 0 $size-lg;

          &:hover,
          &:focus {
            background-color: $gray-300;
          }

          > div {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            height: 100%;

            > span {
              &:first-child {
                color: $gray-900;
                font-size: $font-lg;
                font-weight: bold;
              }
              &:last-child {
                font-size: $font-sm;
              }
            }
          }

          > img {
            height: rem(48);
            width: rem(48);
            border-radius: 50%;
            margin-right: $size-lg;
            object-fit: cover;
          }
        }

        .dropdown-content {
          &.expanded {
            > * {
              &:last-child:hover {
                background-color: $secondary;
              }
            }
          }
        }
      }
    }
  }

  a {
    @include flex-center;
    height: 100%;
    color: $gray-700;
    text-decoration: none;
  }

  @media screen and (max-width: $screen-lg) {
    flex-direction: column;
    align-items: center;
    height: fit-content;
    min-height: $topbar-height;

    .funus-topbar-collapsable {
      flex-direction: column;
      width: 100vw;
      max-width: 100vw;
      position: absolute;
      top: $topbar-height;
      border-bottom: 1px solid $gray-500;
      height: fit-content;
      max-height: 0;
      overflow: hidden;
      @include default-transition;

      &.expanded {
        max-height: calc(100vh - #{$topbar-height});
      }

      .topbar-left {
        display: none;
      }
      .topbar-right {
        flex-direction: column;
        height: fit-content;

        .user-info {
          padding: $size-md 0;
          img {
            display: none;
          }
        }
      }
    }
  }
}
