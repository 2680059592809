@import 'assets/_constants';
@import 'assets/_mixins';

.funus-textarea {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: $size-lg;

  > label {
    position: absolute;
    color: $gray-700;
    font-weight: 100;
    font-size: $font-md;
    left: 0;
    transform: translateY($size-sm);
    @include default-transition;
    pointer-events: none;
  }

  &:not(.filled):not(.focused):not(.prefixed) {
    &.hovered {
      > label {
        left: $size-md;
      }
    }
  }
  &.prefixed:not(.filled):not(.focused) {
    > label {
      left: calc(#{$size-xl} - #{$size-sm});
    }
    &.hovered {
      > label {
        left: $size-xl;
      }
    }
  }

  > div {
    width: 100%;
    border: 0;
    border-bottom: 1px solid $gray-500;
    @include default-transition;

    > div:not(.bottom-line) {
      display: flex;
      align-items: center;
      width: 100%;

      textarea {
        min-height: calc(#{$size-xl} - 1px);
        width: 100%;
        line-height: rem(24);
        padding: $size-xs $size-md;
        border: 0;
        outline: none;
        @include default-transition;
        color: $gray-900;
        background-color: transparent;
        resize: none;
      }

      .textarea-prefix,
      .textarea-suffix {
        @include flex-center;
        @include default-transition;
        color: $gray-700;
        width: calc(#{$size-xl} - #{$size-md});
        padding: 0 $size-sm;
        margin-top: -(1px);
      }

      .clear-button {
        @include default-transition;
        padding: $size-sm;
        &::after {
          padding: $size-xs;
          font-size: $font-lg;
          line-height: 1;
          text-align: center;
          display: table-cell;
          vertical-align: middle;
          content: '\00d7';
        }

        &:focus {
          color: $primary-dark;
        }
      }
    }
    .bottom-line {
      width: 0;
      @include default-transition;
      border-bottom: 1px solid transparent;
    }
  }
  &.disabled {
    > div {
      background-color: $gray-300;
      cursor: no-drop;
      > div {
        textarea {
          cursor: no-drop;
        }
      }
    }
  }
  &:not(.disabled):not(.error) {
    &.hovered,
    &:hover,
    &:focus,
    &:active {
      > div {
        border-bottom-color: $primary-dark;
        background-color: $white;
      }
      .bottom-line {
        width: 100%;
        border-bottom-color: $primary-dark;
      }
    }
  }
  span {
    color: $error;
    font-size: $font-sm;
    @include default-transition;

    &.hidden {
      font-size: 0;
    }
  }

  &.focused,
  &.filled {
    > label {
      font-size: $font-md;
      transform: translateY(-$size-lg);
    }
  }

  &.focused {
    > label {
      font-size: $font-md;
      color: $primary-dark;
    }
    > div {
      border-bottom-color: $primary-dark;
      .bottom-line {
        width: 100%;
        border-bottom-color: $primary-dark;
      }
      > div {
        .textarea-prefix,
        .textarea-suffix,
        .clear-button {
          color: $primary-dark;
        }
      }
    }
  }

  &.error {
    > label {
      color: $error;
    }
    > div {
      border-bottom-color: $error;
      > div {
        .textarea-prefix,
        .textarea-suffix {
          color: $error;
        }
      }
    }
    &.focused,
    &.hovered {
      > div {
        .bottom-line {
          width: 100%;
          border-bottom-color: $error;
        }
      }
    }
  }
}
