@import 'assets/_constants.scss';

.home-container {
  background-color: $white;
}

.error-logo {
  width: 40%;
  max-width: rem(300);
  margin: auto;
  height: auto;
}

.logo-error-img {
  margin-top: 25px;
  width: 100%;
  margin-bottom: 60px;
}

.error-container {
  width: 70%;
  margin: auto;
}

.fade {
  opacity: initial;
}

.accordion > .card > .collapse {
  height: 350px;
  overflow-y: scroll;
}
