@import 'assets/_constants';

.sidebar-dropdown {
  display: flex;
  flex-direction: column;
  padding: 0;

  > .funus-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    position: relative;

    > svg.button-suffix {
      position: absolute;
      width: $size-lg;
      height: $size-lg;
      background-color: $gray-700;
      border-radius: 50%;
      right: $size-md;
      transition: transform $transition-mid ease-in-out;
    }
  }

  .subsections {
    width: calc(100% - #{$size-md});
    margin-left: $size-md;

    > a,
    > .funus-button {
      background-color: transparent;
      height: 0;
      max-height: 0;
      overflow: hidden;
    }
    > .funus-button {
      display: block;
    }
  }

  &.expanded {
    > .funus-button {
      > svg.button-suffix {
        transform: rotate(180deg);
      }
    }
    .subsections {
      > a,
      > .funus-button {
        height: rem(60);
        max-height: rem(60);
      }
    }
  }
}
