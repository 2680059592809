@import 'assets/_constants';
@import 'assets/_mixins';

#forgot-page {
  @include flex-center;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  position: relative;

  .funus-message {
    max-width: calc(100% - #{$size-xl});
    margin-bottom: $size-xl;
  }

  > div:not(.funus-message) {
    display: flex;
    align-items: flex-start;
    max-width: calc(#{rem(600)} + #{$size-xxl});
    width: 100%;

    > * {
      flex: 1;
      width: 100%;
    }

    > img {
      margin-right: $size-xxl;
      max-width: rem(300);
    }

    form {
      max-width: calc(#{rem(300)} + #{$size-xxl});
      padding-left: $size-xxl;
      border-left: 1px solid $gray-300;
      @include flex-column;

      h2 {
        margin-top: 0;
      }

      > *:not(.funus-input):not(:last-child) {
        margin-bottom: $size-lg;
      }

      input {
        text-transform: unset;
      }

      .form-buttons {
        @include flex-reverse;
        margin-top: $size-lg;

        > * {
          flex: 1;
          &:not(:last-child) {
            margin-left: $size-lg;
          }
        }
      }
    }

    @media screen and (max-width: $screen-lg) {
      flex-direction: column;
      align-items: center;
      padding: $size-lg;
      width: calc(100vw - #{$size-xl});

      > img {
        margin-right: 0;
      }

      form {
        margin-top: $size-lg;
        max-width: rem(300);
        padding-left: 0;
        border-left: unset;
      }
    }
  }
}
