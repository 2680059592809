@import 'assets/_constants';
@import 'assets/_mixins';

.form-page {
  @include flex-column;
  align-items: center;
  padding: $size-xl;

  .funus-step-form,
  .form-generator {
    height: calc(100vh - #{$topbar-height} - #{$size-xxl} - #{$size-xxl});
    overflow: auto;

    .fields {
      legend {
        padding: $size-xs $size-lg;
        margin: 0 0 0 (-$size-lg);
        width: calc(100% + #{$size-xl});
        font-size: $font-lg;
        color: $gray-700;
        background-color: rgba($primary, 0.5);
      }

      .funus-input.lowercase {
        input {
          text-transform: inherit;
        }
      }
    }

    fieldset {
      @include flex-column;

      > *:not(:first-child),
      > *:first-child:not(legend) {
        margin-top: $size-lg;
      }
      &:not(:first-child) {
        legend {
          margin-top: $size-lg;
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    align-items: initial;
  }
}
