@import 'assets/_constants';
@import 'assets/_mixins';

.services-page {
  @include flex-column;
  min-height: calc(100vh - #{$topbar-height});

  .services-content {
    position: relative;
    display: flex;
    height: 100%;

    .service-sidebar {
      position: absolute;
      overflow: auto;
      top: 0;
      left: 0;
      bottom: 0;
    }

    h3 {
      @include flex-center;
      justify-content: space-between;
      color: $gray-700;
      font-weight: lighter;
      position: relative;
      width: calc(100% - (#{$size-xxl} * 3));
      margin-bottom: $size-lg;

      @media screen and (max-width: $screen-md) {
        @include flex-column-center;
      }

      .back-button {
        font-size: $font-lg;
        margin-bottom: 0;
        height: 100%;

        svg {
          height: $font-lg;
        }

        @media screen and (max-width: $screen-md) {
          margin-bottom: $size-md;
          align-self: center;
        }
      }

      .clear-button {
        font-size: $font-lg;

        &.hidden {
          opacity: 0;
          pointer-events: none;
        }

        @media screen and (max-width: $screen-md) {
          margin-top: $size-md;
        }
      }
    }

    form {
      @include flex-column;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;

      @media screen and (max-width: $screen-md) {
        align-items: initial;
      }

      > .selected-service {
        @include flex-column;
        align-items: center;
        margin-left: calc(#{rem(150)} + #{$size-lg});
        width: calc(100% - #{$size-xxl});
        max-width: calc(100% - #{rem(150)});
        padding: $size-xl;
        position: relative;

        @media screen and (max-width: $screen-md) {
          margin-left: calc(#{$size-xxl} + #{$size-sm});
          padding: $size-sm;
          padding-top: $size-xl;
          max-width: calc(100% - #{$size-xxl} - #{$size-sm});
          height: 75vh;
        }

        .selected-service-info {
          width: calc(100% - (#{$size-xxl} * 3));
          max-height: calc(100vh - #{$topbar-height} - (#{$size-xxl} * 3));
          height: calc(100vh - #{$topbar-height} - (#{$size-xxl} * 3));
          overflow: auto;
          position: relative;
          padding: 0 0 $size-lg $size-lg;

          @media screen and (max-width: $screen-md) {
            width: 70%;
          }

          .funus-message {
            margin-top: $size-lg;
            width: calc(100% - #{$size-lg});
          }

          > div:not(.funus-message) {
            position: relative;

            &.hidden-service {
              display: none;
            }

            .funus-radio {
              @include flex-start;
              flex-wrap: wrap;

              > label {
                width: 100%;
              }

              > *:not(label) {
                margin-bottom: 0;
                margin-right: $size-md;
              }
            }

            &:not(.hidden-service) {
              fieldset {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                margin-right: 0;
                padding-right: 0;
                & {
                  margin-top: $size-lg;
                }

                @media screen and (max-width: $screen-lg) {
                  flex-direction: column;
                  flex-wrap: nowrap;
                }

                legend {
                  padding: $size-xs $size-lg;
                  margin: 0;
                  width: calc(100% - #{$size-lg});
                  font-size: $font-lg;
                  color: $gray-700;
                  background-color: rgba($primary, 0.5);
                }

                > *:not(legend):not(.funus-table):not(.funus-select-button):not(.full-width) {
                  width: calc(50% - #{$size-lg});
                  margin-top: $size-lg;
                  margin-right: #{$size-lg};

                  @media screen and (max-width: $screen-lg) {
                    width: calc(100% - #{$size-lg});
                  }
                }
                .full-width,
                > .funus-table,
                .funus-select-button {
                  width: calc(100% - #{$size-lg});
                  margin-top: $size-lg;
                }

                .funus-table {
                  border: 1px solid rgba($primary, 0.25);
                  background-color: $white;

                  tr.error {
                    background-color: rgba($error, 0.25);
                  }
                }
              }
            }
          }

          // VIGIL form
          .vigil-form {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            margin-right: 0;
            padding-right: 0;

            & {
              margin-top: $size-lg;
            }

            @media screen and (max-width: $screen-lg) {
              flex-direction: column;
              flex-wrap: nowrap;
            }

            > *:not(:last-child) {
              width: calc(50% - #{$size-lg});
              margin-top: $size-lg;
              margin-right: #{$size-lg};

              @media screen and (max-width: $screen-lg) {
                width: calc(100% - #{$size-lg});
              }
            }

            > :last-child {
              margin-top: $size-lg;
              width: calc(100% - #{$size-lg});
            }
          }
        }
      }

      .action-buttons {
        position: absolute;
        top: $size-xl;
        right: $size-xl;
        z-index: 2;

        @media screen and (max-width: $screen-md) {
          top: $size-md;
          right: $size-md;
        }

        .action-button {
          @include flex-column-center;

          .funus-button {
            @include basic-shadow;
            height: $size-xxl;
            width: $size-xxl;
            padding: 0;
            border-radius: 50%;
            border: 0;

            @media screen and (max-width: $screen-md) {
              height: $size-xl;
              width: $size-xl;
            }
            svg {
              height: $size-xl;
              width: $size-xl;

              @media screen and (max-width: $screen-md) {
                height: $size-lg;
                width: $size-lg;
              }
            }

            &.inverted {
              background-color: $white;
              color: $primary-dark;

              &:hover {
                background-color: $primary-dark;
                color: $white;
              }
            }
          }

          span {
            margin-top: $size-md;
            font-size: $font-sm;
            color: $primary-dark;
          }

          &:not(:last-child) {
            margin-bottom: $size-md;
          }
        }
      }
    }
  }
}
