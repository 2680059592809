
.modal-background{
  &.budget-modal {


    .modal-dialog {
      max-width: none;
      max-height: none;
      margin: 0 0;
      width: 100%;
      height: 100%;
      .modal-header {
        .f-row {
          width: 100%;
          .f-row-item {
            flex-grow: unset;
          }
        }

        h4 {
          width: 100%;
          text-align: center;
          text-transform: capitalize;
        }
      }
    }
  }
}
