@import 'assets/_constants.scss';
@import 'assets/_mixins.scss';

.service-sidebar-button {
  @include flex-column;
  padding: $size-lg 0;
  border-radius: 0;
  border-right: $size-sm solid transparent;

  svg {
    @include default-transition;
    margin-right: -$size-sm;
    height: calc(#{$size-xl} + #{$size-lg});
    width: calc(#{$size-xl} + #{$size-lg});
    stroke-width: 1;

    * {
      stroke-width: 1;
      @include default-transition;
    }
  }

  .custom-icon.button-prefix {
    margin-right: 0;
  }

  > div {
    margin-right: -$size-sm;
    color: $primary-dark;
  }

  &:hover,
  &.selected {
    color: $primary-dark;
    border-right-color: $primary-dark;

    svg,
    svg * {
      color: $primary-dark;
      stroke: $primary-dark;
    }
  }
  &:not(.selected):not(.is-in-use-service) {
    background-color: rgba($primary-dark, 0.25);
    > div {
      color: $gray-500;
    }
  }

  &.error {
    color: $error;

    &:hover,
    &.selected {
      border-right-color: $error;
      background-color: rgba($error, 0.25);

      svg,
      svg * {
        color: $error;
        stroke: $error;
      }

      > div {
        color: $error;
      }
    }

    > div.errors {
      @include flex-center;
      background-color: $error;
      color: $white;
      height: $size-lg;
      width: $size-lg;
      border-radius: 50%;
      position: absolute;
      right: calc(#{$size-lg} + #{$size-md});
    }
  }

  @media screen and (max-width: $screen-md) {
    .button-text {
      display: none;
    }
    svg {
      height: $size-xl;
      width: $size-xl;
    }

    svg,
    svg * {
      stroke-width: 1.5;
    }
  }
}
