@import 'assets/_constants';
@import 'assets/_mixins';

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  button,
  input,
  div,
  span,
  strong,
  a,
  p,
  label,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Lato', sans-serif;
  }
}

#root {
  background-color: $gray-100;

  .Toastify {
    .Toastify__toast-container {
      padding-top: $topbar-height;

      .toast-success {
        background-color: $success;
      }
      .toast-error {
        background-color: $secondary-light;
        border: 1px solid $secondary;
      }
      .toast-info {
        background-color: $accent-blue;
      }
      .toast-warn {
        background-color: $primary;
        border: 1px solid $primary-dark;
      }
      .toast-default {
        background-color: $gray-300;
        border: 1px solid $gray-500;
      }
    }
  }
}

main.app {
  min-height: calc(100vh - #{$topbar-height});
  display: flex;

  header {
    z-index: 1;
    position: fixed;
    right: 0;
    left: $sidebar-width;
    @include default-transition;

    &.expanded {
      left: $sidebar-full-width;
    }

    @media screen and (max-width: $screen-lg) {
      left: 0;
      &.expanded {
        left: 0;
      }
    }
  }
  #page-container {
    @include default-transition;
    display: flex;
    flex-direction: column;
    width: calc(100vw - #{$sidebar-width});
    min-height: calc(100vh - #{$topbar-height});
    position: relative;
    margin-top: $topbar-height;
    overflow: hidden;

    &.f-budget-layout {
      margin-top: 0;
      width: 100vw;
    }

    > *:not(header) {
      z-index: 0;
      max-width: 100%;
      height: 100%;
      overflow: hidden;
    }

    &.sidebar-expanded {
      width: calc(100vw - #{$sidebar-full-width});
    }
    @media screen and (max-width: $screen-lg) {
      width: 100vw;
      &.sidebar-expanded {
        width: 100vw;
      }
    }
  }
}

// ***********
// COLORS
// ***********
.primary-bg {
  background: $primary;
}
.primary-dark-bg {
  background: $primary-dark;
}
.primary-light-bg {
  background: $primary-light;
}
.secondary-bg {
  background: $secondary;
}
.secondary-dark-bg {
  background: $secondary-dark;
}
.secondary-light-bg {
  background: $secondary-light;
}

.primary-color {
  color: $primary;
}
.primary-dark-color {
  color: $primary-dark;
}
.primary-light-color {
  color: $primary-light;
}
.secondary-color {
  color: $secondary;
}
.secondary-dark-color {
  color: $secondary-dark;
}
.secondary-light-color {
  color: $secondary-light;
}

// ***********
// GRADIENTS
// ***********
.gradient-primary {
  background: $gradient-primary;
}
.gradient-secondary {
  background: $gradient-secondary;
}
.gradient-primary-secondary {
  background: $gradient-primary-secondary;
}
.gradient-primary-secondary-light {
  background: $gradient-primary-secondary-light;
}
