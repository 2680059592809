figure {
  position: relative;

  button {
    position: absolute;
    top: 0;
    right: 0;
  }

}

