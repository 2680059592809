@import 'assets/_constants';
@import 'assets/_mixins';

.qr-scan {
  @include flex-column-center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100vh;
  font-size: $font-title;
  font-weight: 200;

  strong {
    color: $primary-dark;
    font-weight: bold;
  }

  .scanner {
    max-height: $height-qrscan;
    max-width: $width-qrscan;
    width: 100%;
    height: 100%;
    padding: 0;

    .close-icon {
      position: relative;
      height: 0px;
      padding: 0px;
      margin: 0px;
      top: calc(#{$size-xl / 2});
      left: calc(#{$width-qrscan} - #{$size-xl});
      z-index: 1;

      > svg {
        height: $size-xl;
        width: $size-xl;
        color: $white;
      }
    }

    .camera-icon {
      position: relative;
      top: calc((#{-$height-qrscan} / 2) - (#{$size-xxl} / 2));
      left: calc((#{$width-qrscan} / 2) - (#{$size-xxl} / 2));
      height: $size-xxl;
      width: $size-xxl;
      color: $white;
    }

    // Library: QR READER
    section {
      @include primary-shadow;
      background-color: $gray-500;
      height: 100%;
      width: 100%;

      > div {
        display: none;
      }

      > video {
        max-height: $height-qrscan;
        width: 100%;
      }
    }

    @media screen and (max-width: $screen-sm) {
      $new-max-width: calc(100% - #{$size-xl});
      max-width: $new-max-width;

      .camera-icon {
        top: calc((-50vh / 2));
        left: calc((#{$new-max-width} / 2) - (#{$size-xl} / 2));
      }

      .close-icon {
        left: $new-max-width;
      }
    }
  }
}
