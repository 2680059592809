@import 'assets/_constants';
@import 'assets/_mixins';

.funus-select {
  display: flex;
  position: relative;

  > .funus-input {
    width: 100%;
    z-index: 2;

    svg {
      @include default-transition;

      &.expanded {
        transform: rotate(180deg);
      }
    }
  }

  .select-options {
    z-index: 3;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: $white;
    max-height: rem(200);
    @include default-transition;
    overflow: auto;

    > * {
      @include default-transition;
      @include flex-start;
      height: 0;
      padding: 0 $size-lg;
      overflow: hidden;
      width: 100%;
      border-radius: 0;
      white-space: nowrap;
      font-size: $font-md;

      &.selected {
        background-color: rgba($primary, 0.25);
      }
    }

    &.expanded {
      @include basic-shadow;

      > * {
        height: $size-xl;

        &:hover,
        &.focused {
          background-color: $gray-300;
          &.selected {
            background-color: rgba($primary, 0.5);
          }
        }
      }
    }
  }

  &.open-top {
    .select-options {
      top: unset;
      bottom: $size-xl;
    }
  }

  &.disabled {
    > .funus-input {
      > div {
        background-color: $gray-300;
      }
    }
  }
}
