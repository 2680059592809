@import 'assets/_constants';
@import 'assets/_mixins';

.funus-button {
  padding: $size-md $size-lg;
  border-radius: $size-sm;
  border: none;
  text-decoration: none;
  @include default-transition;
  @include flex-center;

  .button-prefix {
    margin-right: $size-md;
  }
  .button-suffix {
    margin-left: $size-md;
  }

  .button-text {
    width: max-content;
  }

  &:hover,
  &:focus {
    outline: none;
  }

  &.transparent {
    background-color: transparent;
    color: $gray-700;
  }

  &.primary {
    background-color: $primary-dark;
    color: $white;
    border: 1px solid $primary-dark;
    &:not(.disabled) {
      &:hover,
      &:focus {
        background-color: $secondary;
        border-color: $secondary;
        color: $white;
      }
    }
  }

  &.secondary {
    color: $primary;
    background-color: $white;
    border: 1px solid $primary;
    &:hover,
    &:focus {
      color: $gray-900;
      background-color: $primary;
      border-color: $primary;
    }
  }

  &.attention {
    background-color: $secondary;
    border: 1px solid $secondary;
    color: $white;
    &:not(.disabled) {
      &:hover,
      &:focus {
        border-color: $secondary-light;
        background-color: $secondary-light;
        color: $secondary-dark;
      }
    }
  }

  &.accent {
    background-color: $accent-blue;
    border: 1px solid $accent-blue;
    color: $white;
    &:not(.disabled) {
      &:hover,
      &:focus {
        border-color: $accent-blue-light;
        background-color: $accent-blue-light;
        color: $accent-blue-dark;
      }
    }
  }

  &.special {
    background: $primary-dark;
    border: 1px solid $primary-dark;
    color: $white;
    position: relative;
    transition: none;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: fit-content;
      color: $white;

      > svg {
        width: 0;
        color: $white;
      }
    }

    &:not(.disabled) {
      &:hover,
      &:focus {
        background: $gradient-secondary;
        border-color: $secondary;
        color: $white;

        > div {
          width: 100%;
          color: $white;
          > svg {
            color: $white;
            width: rem(20);
          }
        }
      }
    }
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $gray-300;
    color: $gray-500;
    cursor: no-drop;
    border-color: $gray-300;
  }

  svg {
    stroke-width: 1;
  }
}
