@import 'assets/_constants';
@import 'assets/_mixins';

.reception-landing {
  padding: $size-xl;

  > div {
    display: flex;

    > .funus-box {
      flex: 1;
      max-width: calc(50% - #{$size-lg});
      overflow: hidden;

      &.right-box {
        margin-left: $size-lg;
      }
      &.left-box {
        margin-right: $size-lg;
      }

      &.records-box {
        max-width: calc(60% - #{$size-lg});
      }

      &.enquiries-box {
        max-width: calc(40% - #{$size-lg});
      }
    }

    @media screen and (max-width: $screen-xl) {
      flex-direction: column;

      > .funus-box {
        flex: 1;
        max-width: 100%;

        &.records-box,
        &.enquiries-box {
          max-width: 100%;
        }

        &.right-box {
          margin-left: 0;
        }
        &.left-box {
          margin-right: 0;
          margin-bottom: $size-xl;
        }
      }
    }
  }
}

.one-box {
  > div {
    > .funus-box {
      max-width: calc(100% - #{$size-lg});
    }
  }
}
