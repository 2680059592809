@import 'assets/_constants';
@import 'assets/_mixins';

$sidefilter-width: rem(350);
$expand-width: rem(55);
$sidefilter-diff: rem(350 - 55);
$filters-width: rem(350 - 64);

.sidefilter {
  background-color: $white;
  height: calc(100vh - #{$topbar-height});
  width: $sidefilter-diff;
  padding: 0 $size-xl;
  min-width: $sidefilter-width;
  margin-left: -$sidefilter-diff;
  position: fixed;
  z-index: 2;
  @include default-transition;
  @include flex-column;
  @include basic-shadow;

  .sidefilter-header {
    width: 100%;
    padding: $size-lg 0;
    font-size: $font-subtitle;
    color: $gray-700;
    font-weight: 200;
    margin-top: $size-lg;

    > .expand-sidefilter {
      position: absolute;
      padding: 0;
      color: $primary-dark;
      right: 0;
      top: 0;
      bottom: 0;
      width: rem(55);
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: rem(38) 0;
    }

    > .createWorkOrder {
      position: absolute;
      padding: 0;
      color: $primary-dark;
      right: 0;
      top: rem(70);
      bottom: 0;
      width: rem(55);
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: rem(25) 0;
    }
  }

  .filters {
    width: 100%;
    max-width: rem(0);
    flex-wrap: nowrap;
    opacity: 0;
    @include default-transition;

    > * {
      margin-bottom: $size-lg;
      width: 100%;
    }

    .funus-input.focused:not(.checkbox),
    .funus-textarea.focused {
      > div {
        @include primary-shadow;
      }
    }
  }

  &.expanded {
    margin-left: 0;
    overflow: auto;

    .filters {
      opacity: 1;
      max-width: $filters-width;
      z-index: 1;
    }
  }
}
