@import 'assets/_constants.scss';
@import 'assets/_mixins.scss';

#role-form-page {
  .step-fields {
    .info-field {
      color: $gray-700;
      font-weight: 100;
      strong {
        color: $gray-900;
      }
    }
    .funus-table {
      border: 1px solid rgba($primary, 0.25);
      background-color: $white;
    }

    .declarants-buttons {
      @include flex-start;

      > *:not(:last-child) {
        margin-right: $size-lg;
      }
    }
  }

  .fields {
    h3 {
      width: 100%;
    }

    > div {
      legend {
        margin-left: (-$size-lg);
        margin-right: (-$size-lg);
      }

      fieldset {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > div {
          width: 50%;

          @media screen and (max-width: $screen-lg) {
            width: 100%;
          }
        }
      }
    }
  }
}
