@import 'assets/_constants';

.f-ia-info-form {
  .error-message {
    color: $error;
  }

  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (max-width: $screen-sm) {
    .form-row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}