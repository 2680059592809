.f-budget-summary-actions {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .f-row-item {
    flex-grow: unset;
  }

  .f-budget-summary-actions-group {
    margin-right: 0.5rem;
    .f-budget-summary-actions-group-item{
      margin-right: 0.2rem;
    }
  }
}