@use "sass:math";
$browser-context: 16; // Default

@function stripUnit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@function rem($pixels) {
  @return #{math.div(stripUnit($pixels), stripUnit($browser-context))}rem;
}

// ***********
// COLORS
// ***********
$primary: #d7c835;
$primary-dark: #a29800;
$primary-light: #fffb69;
$funus-green: #90993f;

$secondary: #a2303e;
$secondary-dark: #7a001e;
$secondary-light: #e7556f;

$accent-blue: #4953de;
$accent-blue-dark: #b0ab74;
$accent-blue-light: #ffffd5;

$accent-green: #e3dda3;
$accent-green-dark: #b0ab74;
$accent-green-light: #ffffd5;

$gray-100: #f9f9f9;
$gray-300: #e5e9e5;
$gray-500: #b7c1b7;
$gray-700: #798079;
$gray-800: #505850;
$gray-900: #182618;

$black: #000000;
$white: #ffffff;

$success: #83a200;
$error: #f44336;

$gradient-primary: $primary linear-gradient(90deg, $primary 0%, $primary-light 100%) 0% 0% no-repeat
  padding-box;
$gradient-secondary: $secondary linear-gradient(90deg, $secondary 0%, $secondary-light 100%) 0% 0%
  no-repeat padding-box;
$gradient-primary-secondary: $secondary linear-gradient(90deg, $secondary 0%, $primary 100%) 0% 0%
  no-repeat padding-box;
$gradient-primary-secondary-light: $secondary-light
  linear-gradient(90deg, $secondary-light 0%, $primary-light 100%) 0% 0% no-repeat padding-box;

// ***********
// FONT
// ***********
$font-xs: rem(10);
$font-sm: rem(12);
$font-md: rem(14);
$font-lg: rem(16);
$font-xl: rem(20);
$font-subtitle: rem(24);
$font-title: rem(32);

// ***********
// PADDING, MARGIN AND RADIUS
// ***********
$size-xs: rem(2);
$size-sm: rem(4);
$size-md: rem(8);
$size-lg: rem(16);
$size-xl: rem(32);
$size-xxl: rem(64);

// ***********
// TRANSITIONS
// ***********
$transition-slow: 0.5s;
$transition-mid: 0.25s;
$transition-fast: 0.1s;

// ***********
// OTHER
// ***********
$topbar-height: rem(80);
$sidebar-full-width: rem(225);
$sidebar-width: rem(64);
$size-workorder-header: rem(134);
$size-head-record: rem(77);
$height-qrscan: rem(400);
$width-qrscan: rem(400);

// ***********
// SCREEN SIZES
// ***********
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
