@import 'assets/_constants';
@import 'assets/_mixins';

.dropdown-main {
  .dropdown-toggle,
  .dropdown-toggle:hover,
  .dropdown-toggle:focus,
  .dropdown-toggle:active {
    box-shadow: none;
    border: none;
  }

  ::after {
    display: none;
  }

  .dropdown-menu {
    @include default-transition;
    @include basic-shadow;

    button {
      padding: $size-md;
    }
  }
}
