@import 'assets/_constants';
@import 'assets/_mixins';

.div-select-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(#{$size-workorder-header} + #{$size-md});
  padding: $size-lg $size-lg 0 $size-lg;

  h4 {
    width: 100%;
    color: $gray-800;
  }

  .div-status {
    display: flex;
    width: 100%;
    margin: $size-sm 0px;
    border: 2px solid $funus-green;
    border-radius: $size-md;

    button {
      display: flex;
      justify-content: center;
      flex-basis: 50%;
      padding: $size-sm;
      background-color: transparent;
      color: $funus-green;
      border-radius: 0px;

      &.active {
        background-color: $funus-green;
        color: $white;
      }

      &.primary:focus,
      &.primary:hover {
        background-color: $funus-green;
        color: $white;
      }

      &:not(:first-child):not(:last-child) {
        border: 2px solid $funus-green;
        border-top: none;
        border-bottom: none;
      }
    }
  }

  .div-select {
    padding-bottom: $size-md;
    width: 100%;

    .form-select {
      width: 100%;

      &:focus {
        border-color: $funus-green;
        box-shadow: none;
      }

      option:checked {
        background-color: $funus-green;
      }
    }
  }
}
