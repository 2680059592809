@import 'assets/_constants';
@import 'assets/_mixins';

.funus-dropdown {
  display: flex;
  position: relative;

  > .funus-button {
    @include flex-center;
    border-radius: 0;
  }

  .dropdown-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: $white;
    @include default-transition;
    overflow: hidden;

    > * {
      @include default-transition;
      @include flex-start;
      height: 0;
      padding: 0 $size-lg;
      overflow: hidden;
      width: 100%;
      border-radius: 0;
      white-space: nowrap;

      svg {
        transition: color 0 ease-in-out;
      }
    }

    &.expanded {
      @include basic-shadow;

      > * {
        height: calc(#{$size-xl} + #{$size-lg});

        svg {
          height: $size-xl;
        }

        &:hover {
          background-color: $gray-900;
          color: $primary;
          svg {
            color: $primary;
            transition: color $transition-mid ease-in-out;
          }
        }
      }
    }
  }
}
