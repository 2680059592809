.selectable-item {
  max-width: 50%;
  min-width: 50%;
  justify-content: center;
  padding: 0.5rem 2rem;

  &.selectable-item-hightlight {
    min-width: 100%;
    flex-basis: unset;
  }

  .selectable-item-content {
    min-width: 200px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    padding: 2rem;
  }

  &.selectable-item-selected {
    .selectable-item-content {
      outline: 5px solid #E7556F;
      background-color: #7A001E !important;
      border-color: #E7556F !important;
      color: #FFF !important;
    }
  }
}
