@import 'assets/_constants';
@import 'assets/_mixins';

.f-budget-summary {
  height: 100%;
  flex-wrap: nowrap;

  .f-budget-summary-detail {
    width: 100%;
    height: 25%;
  }
}