@import 'assets/_constants';
@import 'assets/_mixins';

.f-budget-form {
  padding-top: 1rem;

  .f-budget-form-title {
    font-size: 1.5rem;
    font-weight: 100;
    color: $gray-700;
  }

  .f-panel {
    padding: 1rem 1rem 1rem 1rem;
    margin: 0 3rem 1rem 1rem;
    box-shadow:
    0.1rem 0.1rem $gray-700,
    -0.1rem -0.1rem $gray-500;

    .f-column.f-location-panel {
      overflow: visible !important;
    }
    
    .f-budget-form-panel-header {
      font-weight: 600;
    }
  
    .f-budget-options {
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;
    }
  
  }
  .f-hidden {
    display: none;
  }
}

