@import 'assets/_constants';
@import 'assets/_mixins';

#not-found {
  @include flex-center;
  width: 100%;
  min-height: calc(100vh - #{$topbar-height});
  font-size: $font-title;
  font-weight: 200;

  strong {
    color: $primary;
    font-weight: bold;
  }
}
