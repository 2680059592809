@import 'assets/_constants';

.scan-quality-marker {
  position: relative;
  top: 0;
  border-bottom-style: solid;
  border-bottom-width: 0.1px; 

  &.scan-quality-marker-error {
    border-color: $error;
  }

  &.scan-quality-marker-warn {
    border-color: $primary;
  }
  &.scan-quality-marker-success {
    border-color: $success;
  }
}