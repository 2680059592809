@import 'assets/_constants';
@import 'assets/_mixins';

.input-margins {
  margin-top: $size-md;
  margin-bottom: $size-md;
}
legend {
  padding: $size-xs $size-lg;
  margin: 0 0 0 (-$size-lg);
  width: calc(100% + #{$size-xl});
  font-size: $font-lg;
  color: $gray-700;
  background-color: rgba($primary, 0.5);
}