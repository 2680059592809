@import 'assets/_constants';
@import 'assets/_mixins';

.funus-select-button {
  @include flex-start;
  flex-wrap: wrap;

  label {
    margin-right: $size-lg;
    font-size: $font-md;
    color: $gray-700;
  }

  .select-button-options {
    @include flex-start;
    flex-wrap: nowrap;

    > .funus-button {
      border-radius: 0;

      &:first-child {
        border-radius: $size-sm 0 0 $size-sm;
      }

      &:not(:first-child) {
        margin-left: rem(-1);
      }

      &:last-child {
        border-radius: 0 $size-sm $size-sm 0;
      }
    }
  }

  &.error {
    label {
      color: $error;
    }
  }

  > span {
    color: $error;
    font-size: $font-sm;
    @include default-transition;
    width: 100%;

    &.hidden {
      font-size: 0;
    }
  }
}
