@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin flex-start() {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@mixin flex-end() {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@mixin flex-space() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin flex-reverse() {
  display: flex;
  flex-direction: row-reverse;
}
@mixin flex-column() {
  display: flex;
  flex-direction: column;
}
@mixin flex-column-center() {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin default-transition() {
  transition: all $transition-mid ease-in-out;
}

@mixin delayed-transition() {
  transition: all $transition-mid $transition-mid ease-in-out;
}

@mixin basic-shadow() {
  -webkit-box-shadow: 0 0 $size-xs $size-xs rgba($gray-500, 0.25);
  box-shadow: 0 0 $size-xs $size-xs rgba($gray-500, 0.25);
}

@mixin primary-shadow() {
  -webkit-box-shadow: 0 0 $size-xs $size-xs rgba($primary, 0.25);
  box-shadow: 0 0 $size-xs $size-xs rgba($primary, 0.25);
}

@mixin fullscreen() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
