@import 'assets/_constants';
@import 'assets/_mixins';

.workshop-card-header {
  width: 100%;

 .workshop-card-header-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
  max-height: 48px;
  overflow-y: hidden;
  .workshop-card-header-top-name {
    text-transform: uppercase;
  }
 }
 .workshop-card-header-detail {
  display: flex;
  .workshop-card-header-detail-icon{
    text-align: center;
    flex-basis: 5em;
    margin-right: 0.5em;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
    svg {
      width: calc(#{$size-xl} + #{$size-sm});
      height: calc(#{$size-xl} + #{$size-sm});
      margin-bottom: $size-sm;
      margin-top: -$size-sm;
    }
  }
  .workshop-card-header-detail-data{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    }
 } 
}