@import 'assets/_constants';
@import 'assets/_mixins';

.funus-sidebar {
  background-color: $gray-800;
  height: 100vh;
  width: $sidebar-width;
  min-width: $sidebar-width;
  @include default-transition;

  .responsive-expand {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 1010;
    height: rem(80);

    svg {
      @include default-transition;
      color: $primary-dark;
      stroke: $primary-dark;
      transform: rotate(180deg);
      height: $size-xl;
      width: $size-xl;
    }

    @media screen and (max-width: $screen-lg) {
      display: flex;
    }
  }

  > div {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $gray-800;
    height: 100vh;
    width: $sidebar-width;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: $gray-700;
    z-index: 1002;
    @include default-transition;

    .funus-sidebar-brand {
      height: $topbar-height;
      background-color: $gray-100;
      border-right: 1px solid $gray-300;

      > a {
        @include flex-center;
        height: $topbar-height;
        color: $gray-700;

        > div {
          @include default-transition;
          max-width: rem(20);
          overflow: hidden;
        }
      }
    }

    .funus-sidebar-collapsable {
      padding-top: rem(30);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      z-index: 1003;
      background-color: $gray-800;
      overflow: auto;

      .sidebar-dropdown {
        @include default-transition;
        white-space: nowrap;

        > .funus-button {
          border-right: rem(4) solid transparent;
          @include default-transition;
          color: $white;
          padding: 0 0 0 $size-lg;
          font-size: 0;
          height: rem(60);

          &:hover {
            color: $primary;
          }

          > svg:not(.button-suffix) {
            margin-right: $size-md;
            min-width: $size-xl;
            width: $size-xl;
            height: $size-xl;
            stroke-width: 1;
          }
        }

        .subsections {
          background-color: #343f34;

          > a > div,
          > .funus-button > div {
            padding: 0 0 0 $size-md;
          }
        }

        &.active {
          background-color: $gray-700;
          border-right-color: $primary;
        }

        &:hover,
        &.active {
          > div {
            color: $primary;
          }
        }
      }

      a {
        border-right: rem(4) solid transparent;
        @include default-transition;
        font-size: $font-md;
        white-space: nowrap;

        > div {
          @include default-transition;
          color: $white;
          padding: 0 0 0 $size-lg;
          font-size: 0;
          display: flex;
          align-items: center;

          > svg {
            margin-right: $size-md;
            height: $size-xl;
            width: $size-xl;
            stroke-width: 1;
          }
          .custom-icon {
            margin-right: $size-md;
            svg,
            svg > * {
              @include default-transition;
              height: $size-xl;
              width: $size-xl;
            }
            svg * {
              color: $white;
              stroke: $white;
            }
          }
        }

        &.active {
          background-color: $gray-700;
          border-right-color: $primary;
          svg,
          svg * {
            color: $primary;
            stroke: $primary;
          }
        }

        &:hover,
        &.active {
          > div {
            color: $primary;

            svg,
            svg * {
              color: $primary;
              stroke: $primary;
            }
          }
        }
      }
      .funus-button {
        border-right: rem(4) solid transparent;
        font-size: $font-md;
        padding: 0;
        white-space: nowrap;
        width: 100%;

        > div {
          @include default-transition;
          color: $white;
          padding: 0;
          font-size: 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          > svg {
            margin-right: $size-md;
            width: $size-xl;
            height: $size-xl;
            stroke-width: 1;
          }
        }

        &:hover {
          > div {
            color: $primary;
            svg,
            svg * {
              stroke: $primary;
            }
          }
        }
      }
      .sidebar-top,
      .sidebar-bottom {
        display: flex;
        flex-direction: column;
        > a,
        > .funus-button {
          height: rem(60);
        }
      }

      .sidebar-bottom {
        font-weight: 200;
        text-align: center;
        font-size: $font-subtitle;
        color: $primary;
        display: flex;
        justify-content: center;

        > div:not(.expand-sidebar) {
          @include default-transition;
          padding: $size-xl 0;
          overflow: hidden;
          max-width: 0;
        }

        .expand-sidebar {
          background-color: $gray-700;
          color: $primary;
          display: flex;
          justify-content: center;
          padding: $size-lg 0;
          bottom: 0;
          border-radius: 0;

          svg {
            height: $size-xl;
            width: $size-xl;
            @include default-transition;
          }

          &:hover {
            background-color: $gray-900;

            svg {
              transform: translateX($size-sm);
            }
          }
        }
      }
    }

    a,
    .sidebar-dropdown {
      width: 100%;
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  }

  &.expanded {
    width: $sidebar-full-width;
    min-width: $sidebar-full-width;
    > div {
      width: $sidebar-full-width;
      .funus-sidebar-brand {
        > a {
          > div {
            max-width: 100%;
            transition: all ease-in-out $transition-mid $transition-fast;
          }
        }
      }
      .funus-sidebar-collapsable {
        a > div,
        .sidebar-dropdown .funus-button,
        .funus-button > div {
          font-size: $font-md;
        }
        .sidebar-bottom {
          > div:not(.expand-sidebar) {
            max-width: $sidebar-full-width;
            padding: $size-xl $size-lg;
          }

          .expand-sidebar {
            svg {
              transform: rotate(180deg);
            }
            &:hover {
              svg {
                transform: translateX(-$size-sm) rotate(180deg);
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $screen-lg) {
    width: 0;
    min-width: 0;
    background-color: transparent;
    > div {
      width: 0;
      overflow: visible;
      background-color: transparent;
      .funus-sidebar-brand {
        margin-bottom: 0;
        width: 100vw;
        > a {
          > div {
            max-width: 100%;
          }
        }
      }

      .funus-sidebar-collapsable {
        width: 0;
        max-width: $screen-sm;
        pointer-events: none;
        @include default-transition;

        .sidebar-bottom {
          .expand-sidebar {
            display: none;
          }
        }
      }
    }

    &.expanded {
      width: 0;
      min-width: 0;
      > div {
        width: 0;
        .funus-sidebar-collapsable {
          width: 100vw;
          pointer-events: all;

          .sidebar-bottom {
            .expand-sidebar:hover {
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }
}
