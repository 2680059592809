.f-article-amount {
  .funus-button {
    width: 48px;
    height: 48px;
  }

  .f-row-item {
    flex-grow: unset;
  }

  .funus-input:not(.checkbox):not(.radio) {
    padding-top: 0;
    > div {
      width: unset;
      > div {
        width: unset;
        > input {
          width: 5rem;
        }
      }
    }
  }
}

