@import 'assets/_constants';
@import 'assets/_mixins';

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s ease-in-out infinite;
  -moz-animation: rotating 2s ease-in-out infinite;
  -ms-animation: rotating 2s ease-in-out infinite;
  -o-animation: rotating 2s ease-in-out infinite;
  animation: rotating 2s ease-in-out infinite;

  width: rem(53);
  overflow: hidden;
  height: rem(100);

  img {
    height: rem(100);
  }

}
