@import 'assets/_constants';
@import 'assets/_mixins';

.document-dni-data-collector-component {
  .dni-data-collector-buttons {
    @include flex-space;
    margin-top: 8px;
    margin-bottom: 8px;
  }


  .summary-photo-preview {
    display: flex;
    justify-content: space-around;

    .document-dni-data-collector-image {
      text-align: center;
      width: 40%;
      img {
        width: 100%;
      }
    }
  }

  legend {
    margin: 16px 0 0 0;
    width: 100%;
  }

  .step-header {
    text-align: center;
    font-size: 1.5rem;
   }
}