@import 'assets/_constants';
@import 'assets/_mixins';

.image-input {
  .display-container {
    width: rem(200);
    height: rem(200);
    span,
    img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
    }
  }
  .open-button {
    float: left;
  }

  input[type='file'] {
    display: none;
  }

  button {
    float: right;
    margin-left: $size-sm;
  }
}

.disagreement-layout {
  .display-container {
    width: rem(200);
    img {
      width: 100%;

    }
  }

  input[type='file'] {
    display: none;
  }

}
