@import 'assets/_constants.scss';
@import 'assets/_mixins.scss';

.service-sidebar {
  @include basic-shadow;
  @include flex-column;
  background-color: $white;
  width: rem(150);

  svg,
  svg * {
    stroke: #b7c1b7;
    stroke-width: 2;
  }

  .validate-button {
    margin: $size-lg;
    font-size: $font-md;
    &:last-child {
      margin-top: 0;
    }
  }

  @media screen and (max-width: $screen-md) {
    width: $size-xxl;
  }
}
