@import 'assets/_constants';
@import 'assets/_mixins';

.expandable-box {
  @include flex-column;
  @include basic-shadow;
  @include default-transition;
  padding: $size-xl;
  position: fixed;
  top: $topbar-height;
  right: 0;
  bottom: 0;
  max-width: rem(800);
  width: 100%;
  margin-right: rem(-800);
  overflow: auto;
  background-color: $white;
  z-index: 20;

  &.expanded {
    margin-right: 0;
  }

  .close-button {
    position: absolute;
    right: $size-lg;
    color: $primary;
  }

  @media screen and (max-width: $screen-md) {
    max-width: 100%;
    margin-right: -100%;
  }
}
