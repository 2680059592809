.gallery-image-picture {
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;

  &.gallery-image-picture-image-full {
    .gallery-image-picture-image {
      width: 60vw;
      height: auto;
    }
  }
}