@import 'assets/_constants';
@import 'assets/_mixins';

.funus-message {
  @include flex-column;
  border-radius: $size-sm;
  padding: $size-md;
  color: $white;
  border-width: 1px;
  border-style: solid;

  &.success {
    background-color: $success;
    border-color: $success;
  }
  &.error {
    background-color: $secondary-light;
    border-color: $secondary;
    color: $secondary-dark;
  }
  &.info {
    background-color: $accent-blue;
    border-color: $accent-blue;
  }
  &.warning {
    background-color: $primary;
    border-color: $primary-dark;
    color: $gray-800;
  }
}
