@import 'assets/_constants';
@import 'assets/_mixins';

.modal-background {
  @include flex-center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1010;
  background-color: rgba($gray-900, 0.25);

  .modal-dialog {
    @include flex-column;
    pointer-events: all;
    max-height: 94vh;
    max-width: rem(1000);
    min-width: rem(250);
    height: fit-content;
    z-index: 1011;
    background-color: $white;
    box-shadow: $size-md $size-md $size-sm #aaaaaa;

    .modal-header {
      border: 0;
    }

    .modal-body {
      overflow: auto;
    }

    .modal-footer {
      justify-content: flex-start;
      border: 0;
      padding: $size-md;
    }
  }
}
