@import 'assets/_constants.scss';
@import 'assets/_mixins.scss';

.top-info {
  @include basic-shadow;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  background-color: $white;
  padding: $size-lg;
  z-index: 1;
  max-height: $size-head-record;
}
