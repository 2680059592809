@import 'assets/_constants';
@import 'assets/_mixins';

.ia-modal {
  &.modal-background .modal-dialog {
    max-width: 70vw;
    width: 70vw;
  }

  .buttons-panel {
    @include flex-space;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  @media screen and (max-width: $screen-sm) {
    &.modal-background .modal-dialog {
      max-width: 100vw;
      width: 100vw;
    }
    .buttons-panel {
      @include flex-column;
      margin-top: 8px;
      margin-bottom: 8px;
      & > * {
        margin-bottom: 8px;
      }
    }
  }
}