@import 'assets/_constants';
@import 'assets/_mixins';

.f-budget-cart {
  .f-budget-cart-container {
    position: relative;
    overflow: visible;

      .f-loading-items {
        .rotating {
          width: rem(25);
          img {
            height: rem(50);
          }
        }
      }

    .f-budget-cart-content {
      background-color: $white;
      position: absolute;
      right: 0;
      top: 0.2rem;
      min-width: 16rem;
      border: 1px solid $gray-700;
      border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
      z-index: 9999;
      
      display: none;
      &.f-budget-cart-content-visible {
        display: block;
      }
      
      .f-budget-cart-detail {
        max-height: 50vh;
        overflow-y: auto;
        overflow-x: hidden;

        .f-budget-cart-detail-line {
          border-bottom: 0.1rem solid $gray-500;
          padding: 0 0.3rem 0 0.3rem;
          .f-budget-cart-detail-line-description {
            font-size: 0.8rem;
            padding-right: 0.4rem;
          }
          .f-budget-cart-detail-line-price {
            text-align: right;
            padding-right: 0.1rem;
          }
          .f-budget-cart-detail-remove {
            flex-basis: 2rem;
            flex-shrink: 0;
            .custom-icon svg {
              height: 16px;
              cursor: pointer;
            }
          }
        }

      }

      .f-budget-cart-summary {
        padding: 0 0.3rem 0 0.3rem;
        font-weight: 600;
        background-color: $gray-300;
        .f-budget-cart-summary-caption {
          text-transform: uppercase;
          font-size: 0.8rem;
        }
        .f-budget-cart-summary-amount {
          text-align: right;
        }
      }
    }
  }
}