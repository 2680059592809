@import 'assets/_constants';
@import 'assets/_mixins';

#new-password-page {
  @include flex-center;
  width: 100vw;
  min-height: 100vh;
  position: relative;

  > div:not(.loader) {
    display: flex;
    align-items: flex-start;
    max-width: calc(#{rem(600)} + #{$size-xxl});
    width: 100%;

    > * {
      flex: 1;
      width: 100%;
    }

    > img {
      margin-right: $size-xxl;
      max-width: rem(300);
    }

    form {
      max-width: calc(#{rem(300)} + #{$size-xxl});
      padding-left: $size-xxl;
      border-left: 1px solid $gray-300;
      @include flex-column;

      h2 {
        margin-top: 0;
      }

      > *:not(:last-child) {
        margin-bottom: $size-lg;
      }

      > .funus-button {
        margin-top: $size-md;
        width: 100%;
      }
    }

    .form-error {
      border-left: 1px solid $gray-300;
      padding-left: $size-xxl;
      .funus-message {
        .forgot-button {
          margin-top: $size-md;
        }
      }
    }

    @media screen and (max-width: $screen-lg) {
      flex-direction: column;
      align-items: center;
      padding: $size-lg;
      width: calc(100vw - #{$size-xl});

      > img {
        margin-right: 0;
      }

      form {
        margin-top: $size-lg;
        max-width: rem(300);
        padding-left: 0;
        border-left: unset;
      }
    }
  }
}
