.f-budget-invoice-modal {

  .modal-dialog {
    width: 90vw;
    max-width: 90vw;
  }

  table {
    width: 100%;
  }
}