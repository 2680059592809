.budget-summary-total {
  td {
    padding-top: 1rem;
  }
  .budget-summary-total-data {
    text-align: right;
  }

  .budget-summary-total-label {
    font-weight: 600;
    &.budget-summary-total-label-total {
      text-transform: capitalize;
    }
  }
}