@import 'assets/_constants';
@import 'assets/_mixins';

.funus-tooltip {
  position: relative;

  &:hover {
    .tooltip-content {
      opacity: 1;
    }
  }
}
.tooltip-content {
  @include default-transition;
  background-color: rgba($gray-900, 0.9);
  position: fixed;
  white-space: normal;
  color: $white;
  z-index: 1110;
  font-size: $font-lg;
  padding: $size-md;
  pointer-events: none;
  border-radius: $size-sm;

  &::after {
    content: ' ';
    position: absolute;
  }

  &.top {
    &::after {
      border-left: $size-md solid transparent;
      border-right: $size-md solid transparent;
      border-top: $size-md solid rgba($gray-900, 0.9);
      left: calc(50% - #{$size-md});
      bottom: -$size-md;
    }
  }
  &.bottom {
    &::after {
      border-left: $size-md solid transparent;
      border-right: $size-md solid transparent;
      border-bottom: $size-md solid rgba($gray-900, 0.9);
      left: calc(50% - #{$size-md});
      top: -$size-md;
    }
  }
  &.left {
    &::after {
      border-top: $size-md solid transparent;
      border-bottom: $size-md solid transparent;
      border-left: $size-md solid rgba($gray-900, 0.9);
      top: calc(50% - #{$size-md});
      right: -$size-md;
    }
  }
  &.right {
    &::after {
      border-top: $size-md solid transparent;
      border-bottom: $size-md solid transparent;
      border-right: $size-md solid rgba($gray-900, 0.9);
      top: calc(50% - #{$size-md});
      left: -$size-md;
    }
  }
}
