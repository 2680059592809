@import 'assets/_constants';
@import 'assets/_mixins';

.agenda-page {
  padding: $size-xl;

  .agenda-toolbar {
    @include flex-space;
    margin-bottom: $size-lg;

    .funus-datepicker {
      max-width: rem(110);
    }
  }

  .rbc-calendar {
    @include basic-shadow;
    height: calc(100vh - #{$topbar-height} - #{$size-xxl} - #{$size-xxl} - #{$size-xs});

    .rbc-time-header.rbc-overflowing .rbc-label.rbc-time-header-gutter {
      background-color: $primary-dark;
    }

    .rbc-current-time-indicator {
      background-color: $secondary;
    }

    .rbc-time-header-content {
      .rbc-allday-cell {
        display: none;
      }
      .rbc-header {
        padding: $size-lg;
        background-color: $primary-dark;
        border: none;
        color: $white;
        font-weight: 400;
        text-transform: capitalize;
      }
      > .rbc-row.rbc-row-resource {
        border: none;
      }
    }

    .rbc-today {
      background-color: transparent;
    }

    .rbc-row-content {
      z-index: 1;
    }
    .rbc-timeslot-group {
      &:nth-child(even) {
        background-color: rgba($primary, 0.15);
      }
    }

    .rbc-events-container {
      > div {
        padding: 0;
        margin: 0;
        border-color: $primary-dark;

        .rbc-event-label {
          display: none;
        }
      }
    }
  }
}
