@import 'assets/_constants';
@import 'assets/_mixins';

.document-camera {
  position: relative;

  .button-group,
  .action-section {
    @include flex-space;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .photo-camera-button-take {
    width: 100%;
  }

  @media screen and (max-width: $screen-sm) {
    .action-section {
      flex-direction: column;

      .funus-button {
        margin-bottom: 8px;
      }
    }
  }

  margin-bottom: 3.5rem;

  .button-group {
    flex-direction: row;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 4rem;
    background-color: $white;
    padding: 1rem;
    margin: 0 auto;
    width: 100vw;
  }
}