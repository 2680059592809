@import 'assets/_constants';
@import 'assets/_mixins';

.assigned-users {
  margin-top: $size-lg;
  width: 100%;

  span {
    font-weight: bold;
    color: $primary-dark;
  }

  table {
    border: 1px solid $primary-dark;
    position: relative;
  }
}
