@import 'assets/_constants';
@import 'assets/_mixins';

.funus-box {
  @include flex-column;
  @include basic-shadow;
  background-color: $white;
  border-radius: $size-sm;

  .box-header {
    @include flex-start;
    justify-content: space-between;
    padding: $size-lg;

    .header-main {
      display: flex;

      > svg {
        height: rem(41);
        width: rem(41);
        color: $secondary;
        stroke-width: 1;
      }

      > span {
        font-size: $font-xl;
        color: $gray-800;
        font-weight: bold;
      }

      svg + span {
        margin-top: $size-md;
      }
    }

    @media screen and (max-width: $screen-sm) {
      flex-direction: column;
      padding: $size-lg;
      .header-main {
        margin-bottom: $size-lg;
      }
    }
  }
}
