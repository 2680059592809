@import 'assets/_constants';
@import 'assets/_mixins';

.form-generator {
  @include flex-column;
  @include basic-shadow;
  @include default-transition;
  padding: $size-xl;
  width: 100%;
  background-color: $white;

  form {
    @include flex-column;

    > *:not(.form-buttons):not(.funus-carousel.fullscreen):not(:first-child) {
      margin-top: $size-lg;
    }

    .funus-input.focused:not(.checkbox),
    .funus-textarea.focused {
      > div {
        @include primary-shadow;
      }
    }

    .form-buttons {
      flex: 0 100%;
      margin-top: $size-lg;
      display: flex;

      > *:not(:first-child) {
        margin-left: $size-lg;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    max-width: 100%;
    margin-right: -100%;
  }
}
