@import './_constants.scss';

.fadein {
  -webkit-animation: fadein $transition-slow; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein $transition-slow; /* Firefox < 16 */
  -ms-animation: fadein $transition-slow; /* Internet Explorer */
  -o-animation: fadein $transition-slow; /* Opera < 12.1 */
  animation: fadein $transition-slow;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
