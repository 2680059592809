@import 'assets/_constants';
@import 'assets/_mixins';

#enquiries-page {
  .socket-topbar {
    .funus-button {
      padding: 0;

      img {
        height: rem(44);
      }
    }
    > div {
      @include flex-column-center;
      color: $primary-dark;
      font-size: $font-md;

      span {
        font-size: $font-xl;
        color: $gray-900;
      }
    }
  }

  .group-cards {
    display: flex;
    padding: $size-lg;
    height: calc(100vh - #{$topbar-height} - (2 *#{$size-md}));

    > .not-allowed {
      @include flex-center;
      width: 100%;
      font-size: $font-subtitle;
      text-align: center;
      padding: $size-lg;

      .funus-message {
        max-width: rem(600);
      }

      strong,
      p {
        color: $white;
      }
      p {
        margin: 0;
      }

      strong {
        font-size: $font-title;
        margin-top: $size-lg;
      }
    }

    &.record {
      min-height: calc(100vh - #{$topbar-height} - #{$size-head-record} - (2 *#{$size-md}));
      max-height: calc(100vh - #{$topbar-height} - #{$size-head-record} - (2 *#{$size-md}));
    }

    &.mobile {
      padding: 0px;
      min-height: calc(100vh - #{$topbar-height} - #{$size-workorder-header});
      max-height: calc(100vh - #{$topbar-height} - #{$size-workorder-header});

      &.record {
        min-height: calc(
          100vh - #{$topbar-height} - #{$size-workorder-header} - #{$size-head-record} - (2 *#{$size-md})
        );
        max-height: calc(
          100vh - #{$topbar-height} - #{$size-workorder-header} - #{$size-head-record} - (2 *#{$size-md})
        );
      }

      // COLUMNS
      > div:not(.loader) {
        width: 100%;
        margin: 0px;
      }
    }

    // COLUMNS
    > div:not(.loader):not(.not-allowed) {
      @include flex-column;
      width: 33.33333%;
      max-width: calc(33.33333% - #{$size-xl});
      margin: 0 $size-lg;

      @media screen and (max-width: $screen-lg) {
        max-width: inherit;
        width: 100%;
      }

      .no-elements {
        @include flex-column-center;
        height: 100%;

        svg {
          margin-bottom: $size-lg;
        }
      }

      h4 {
        font-weight: bold;
        color: $gray-700;
        margin: 0 0 $size-lg 0;
        font-size: $font-xl;
      }

      > div {
        @include default-transition;
        @include flex-column;
        padding: $size-lg;
        height: calc(100% - #{$size-xl});
        background-color: $white;
        overflow: auto;

        // ITEMS
        > div {
          @include default-transition;

          &:not(:last-child) {
            margin-bottom: $size-md;
          }
        }

        > span {
          align-self: center;
        }
      }

      &:first-child {
        h4 {
          font-weight: lighter;
        }
        > div {
          overflow-x: hidden;
          margin-left: 0;
          background-color: $gray-100;
          border: 1px dashed $gray-500;
        }
      }
      &:not(:first-child):not(:last-child) {
        > div {
          @include basic-shadow;
          overflow-x: hidden;
        }
      }
      &:last-child {
        h4 {
          color: $gray-500;
        }
        > div {
          overflow-x: hidden;
          margin-right: 0;
          background-color: $gray-300;
          border: 1px solid $gray-500;
        }
      }
    }
  }
  &.fullpage {
    height: 100vh;
    .group-cards {
      > div:not(.loader) {
        position: relative;
        > div {
          overflow: hidden;
        }
      }
      .more-card {
        @include flex-start;
        padding: $size-md;
        background-color: rgba($white, 0.25);
        border: 1px solid $gray-500;
        color: $secondary-dark;
        font-size: $font-md;
        font-weight: bold;
        background-color: $white;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .card-number {
        @include flex-center;
        border-radius: 100%;
        background-color: $secondary-dark;
        color: $white;
        height: calc(#{$size-md} + #{$size-lg});
        margin-left: $size-md;
        margin-right: $size-sm;
        width: calc(#{$size-md} + #{$size-lg});
      }
    }
  }
}
