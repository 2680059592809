@import 'assets/_constants';

.back-button {
  align-self: flex-start;
  padding: 0;
  margin-bottom: $size-lg;

  &:hover {
    color: $primary-dark;
  }
}
