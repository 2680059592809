@import 'assets/_constants.scss';
@import 'assets/_mixins.scss';

.preparation-form {
  .applicant-signature {
    .sign {
      border: 1px solid $gray-500;
      max-width: 100%;

      > div {
        max-height: rem(150);
        width: 100% !important;
      }
    }
  }
}
