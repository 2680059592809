@import 'assets/_constants';
@import 'assets/_mixins';

.sign-modal {
  .modal-dialog {
    .modal-body {
      padding-top: 0;
      width: 80vw;
      max-width: calc(#{rem(700)} + #{$size-xs});
      min-width: calc(#{rem(235)} + #{$size-xs});

      > p {
        margin: 0 0 $size-lg 0;
      }

      .sign {
        max-width: none;

        > div {
          width: calc(80vw - #{$size-xl}) !important;
          max-width: calc(#{rem(700)} - #{$size-xl} - #{$size-xs});
          min-width: calc(#{rem(235)} - #{$size-xl} - #{$size-xs});
        }
        > img {
          max-width: calc(#{rem(700)} - #{$size-xl});
        }
      }
      .sign-buttons {
        @media screen and (max-width: $screen-md) {
          @include flex-column;

          > * {
            margin: $size-md 0 0 0;

            &:first-child {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
