@import 'assets/_constants';
@import 'assets/_mixins';

.calendar-page {
  padding: $size-xl;

  .calendar-toolbar {
    @include flex-space;
    margin-bottom: $size-lg;

    .funus-datepicker {
      max-width: rem(100);
    }
  }

  .rbc-calendar {
    @include basic-shadow;
    height: calc(100% - #{$size-xl} - #{$size-xxl});

    .rbc-show-more {
      color: $primary-dark;
    }

    .rbc-header {
      padding: $size-lg;
      background-color: $primary-dark;
      border-color: $primary;
      color: $white;
      font-weight: 400;
      text-transform: capitalize;
    }

    .rbc-row-content {
      z-index: 1;
    }

    .rbc-today {
      background-color: rgba($primary, 0.15);
    }

    .rbc-row-segment {
      > div,
      .rbc-event,
      .rbc-selected {
        background-color: $white;
        padding: 0;
        margin: 0;
        border: 0;
        outline: 0;

        > div {
          border: 1px solid $primary-dark;
          border-radius: $size-sm;
        }

        .rbc-event-label {
          display: none;
        }
      }
    }
  }
}

div.rbc-overlay {
  z-index: 1002;

  .rbc-overlay-header {
    background-color: $primary-dark;
    color: $white;
  }

  .rbc-event,
  .rbc-event.rbc-selected,
  .rbc-day-slot .rbc-background-event {
    cursor: unset;
    padding: 0;
    border: 1px solid $primary-dark;
    border-radius: $size-sm;
    overflow: auto;
    outline: 0;
  }
}
