.q-container{
  padding: 1rem 1rem;

  .f-column {
    flex-grow: 1;
    flex-wrap: nowrap;
    overflow: auto;
  }
  .funus-input:not(.checkbox):not(.radio) > label {
    font-size: 2rem;
  } 

  .funus-input:not(.checkbox):not(.radio) > div > div:not(.bottom-line) input {
    height: 3.5rem;
    font-size: 1.5rem;
  }

  .funus-input:not(.checkbox):not(.radio).filled > label {
    transform: translateY(-2rem);
  }

  .funus-select .select-options.expanded > * {
    height: 3rem;
  }

  .funus-select .select-options > * {
    font-size: 1.5rem;
  }

  .funus-input.checkbox label {
    font-size: 2rem;
  }
}