@import 'assets/_constants';
@import 'assets/_mixins';

.gallery-image {
  flex-basis: 50%;
  padding: 0.5rem;
  flex-wrap: nowrap;
  cursor: pointer;
  &.selected {
    background-color: rgba(219, 219, 219, 0.462);
  }

  .gallery-image-info {
    flex-basis: 50%;
  }

  .quantity-selector-placeholder {
    height: 42px;
  }

  .gallery-image-picture{
    height: 12rem;
    width: 18rem;
    .gallery-image-picture-image {
      height: 100%;
      width: 100%;
      flex-basis: unset;
      flex-grow: unset;
    }
  }

  &.gallery-image-zoom {
    .gallery-image-picture{
      .gallery-image-picture-image {
        cursor: zoom-in;
      }
    }
  }

  &.gallery-image-without-picture,
  &.gallery-image-dismiss-service {
    .gallery-image-picture{
      padding: 3.5rem;
      background-color: $gray-300;
    }
  }
}