@import 'assets/_constants';
@import 'assets/_mixins';

.event-calendar {
  background-color: $white;
  color: $primary-dark;
  padding: $size-xs $size-md;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;

  .f-event-calendar-header {
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
  }

  .f-event-calendar-header-icon {
    flex-grow: unset;
  }

  .f-exta-info-button {
    width: 1rem;
    height: 1.5rem;
  }

  .custom-icon svg {
    height: $font-subtitle;
    width: $font-subtitle;
    color: $primary-dark;
    margin-right: $size-lg;
    stroke-width: 2;
  }

  .event-users {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-sm;
  }
}
