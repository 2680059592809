@import 'assets/_constants';
@import 'assets/_mixins';

.budget-summary-line {
  align-items: center;
  border-color: $primary;
  border-width: 0 0 1px 0;

  .budget-summary-line-code {
    padding-left: 0.5rem;
    width: 7rem;
    text-align: left;
  }
  .budget-summary-line-description {
    width: 40rem;
  }
  .budget-summary-line-amount {
    text-align: right;
    width: 15rem;
    padding-right: 0.5rem;
  }
  .budget-summary-line-price {
    text-align: right;
    width: 15rem;
    padding-right: 0.5rem;
  }
  .budget-summary-line-actions {
    padding: 0 0.5rem;
    width: 6rem;
  }
}
