@import 'assets/_constants';
@import 'assets/_mixins';

.funus-bank-account {
  @include flex-column;

  .bank-inputs {
    display: flex;
    flex-wrap: nowrap;

    > * {
      flex: 1;

      &.bank-2 {
        flex: 2;
      }

      &.bank-3 {
        flex: 3;
      }
    }
  }

  .bank-info {
    font-size: $font-xs;
    color: $gray-700;

    span:not(:last-child) {
      margin-right: $size-md;
    }
  }
}
