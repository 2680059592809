.date-hour-cell {
  display: flex;
  justify-content: space-around
}

.text-align-center{
  text-align: center;
}

.is-judicial-death {
  background-color: #f1f1f19e;
}