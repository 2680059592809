@import 'assets/_constants';
@import 'assets/_mixins';

.sign-container {
  @include flex-column-center;

  .sign {
    max-width: $width-qrscan;
    border: 1px solid $gray-500;
    width: 100%;
    height: 100%;
    padding: 0;

    .disabled {
      cursor: no-drop;
    }

    &.filled {
      background-color: $gray-300;

      > img {
        max-width: 100%;
      }
    }
  }

  .sign-buttons {
    @include flex-start;
    width: 100%;
    margin-top: $size-lg;

    > .funus-button {
      &:not(:last-child) {
        margin-right: $size-lg;
      }
    }
  }
}
