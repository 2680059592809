@import 'assets/_constants';
@import 'assets/_mixins';

.funus-datepicker {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: $size-lg;

  > label {
    position: absolute;
    color: $gray-700;
    font-weight: 100;
    font-size: $font-md;
    left: 0;
    transform: translateY($size-sm);
    @include default-transition;
    pointer-events: none;
    z-index: 1;
  }

  &:not(.filled):not(.focused):not(.prefixed) {
    &.hovered {
      > label {
        left: $size-md;
      }
    }
  }
  &.prefixed:not(.filled):not(.focused) {
    > label {
      left: calc(#{$size-xl} - #{$size-sm});
    }
    &.hovered {
      > label {
        left: $size-xl;
      }
    }
  }

  > div {
    width: 100%;
    border: 0;
    border-bottom: rem(1) solid $gray-500;
    @include default-transition;
    position: relative;

    > div:not(.bottom-line) {
      display: flex;
      align-items: center;
      width: 100%;

      .hidden-input {
        display: none;
      }

      input {
        width: 100%;
        height: #{$size-xl};
        padding: $size-md;
        border: 0;
        outline: none;
        @include default-transition;
        color: $gray-900;
        background-color: transparent;
      }

      .react-datepicker {
        @include basic-shadow;
        border: none;
      }

      .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
      .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
        margin-left: rem(-44);
      }
      .react-datepicker__close-icon {
        @include default-transition;
        padding: $size-sm;
        top: rem(6);

        &::after {
          @include default-transition;
          color: $gray-700;
          background-color: transparent;
          height: $size-lg;
          width: $size-lg;
          padding: $size-xs;
          font-size: $font-lg;
          border-radius: 0;
          text-align: center;
          display: table-cell;
          vertical-align: middle;
          content: '\00d7';
        }

        &:focus {
          &::after {
            color: $primary-dark;
          }
        }
      }

      .react-datepicker__month-wrapper {
        white-space: nowrap;

        .react-datepicker__month-text {
          font-size: $font-lg;
          width: calc(#{$size-xxl} + #{$size-lg});
        }
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected,
      .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item--selected {
        background-color: $primary-dark;
      }

      .react-datepicker__year-read-view--down-arrow,
      .react-datepicker__month-read-view--down-arrow,
      .react-datepicker__month-year-read-view--down-arrow,
      .react-datepicker__navigation-icon::before {
        border-color: white;
      }
      .react-datepicker__header {
        background-color: $primary-dark;

        &.react-datepicker-year-header,
        .react-datepicker__current-month,
        .react-datepicker__day-name,
        .react-datepicker__navigation {
          color: $white;
          font-weight: 400;
        }
      }

      .react-datepicker-popper {
        z-index: 3;
      }

      .react-datepicker__header--time.react-datepicker__header--time--only {
        display: none;
      }

      .input-prefix,
      .input-suffix {
        @include flex-center;
        @include default-transition;
        color: $gray-700;
        width: calc(#{$size-xl} - #{$size-md});
        padding: 0 $size-sm;
        margin-top: -(rem(1));
      }

      .clear-button {
        @include default-transition;
        padding: $size-sm;
        &::after {
          padding: $size-xs;
          font-size: $font-lg;
          line-height: 1;
          text-align: center;
          display: table-cell;
          vertical-align: middle;
          content: '\00d7';
        }

        &:focus {
          color: $primary-dark;
        }
      }
    }
    .bottom-line {
      width: 0;
      @include default-transition;
      border-bottom: $size-xs solid transparent;
      position: absolute;
    }
  }
  &.disabled {
    > div {
      background-color: $gray-300;
      cursor: no-drop;
      > div {
        input {
          cursor: no-drop;
        }
      }
    }
  }
  &:not(.disabled) {
    &.hovered,
    &:hover,
    &:focus,
    &:active {
      > div {
        background-color: $white;
      }
      .bottom-line {
        width: 100%;
      }
    }
  }
  &:not(.disabled):not(.error) {
    &.hovered,
    &:hover,
    &:focus,
    &:active {
      > div {
        border-bottom-color: $primary-dark;
      }
      .bottom-line {
        border-bottom-color: $primary-dark;
      }
    }
  }
  &.focused,
  &.filled {
    > label {
      font-size: $font-md;
      transform: translateY(-$size-lg);
    }
  }

  span {
    color: $error;
    font-size: $font-sm;
    @include default-transition;

    &.hidden {
      font-size: 0;
    }
  }

  &.focused {
    > label {
      font-size: $font-md;
      color: $primary-dark;
    }
    > div {
      border-bottom-color: $primary-dark;
      .bottom-line {
        width: 100%;
        border-bottom-color: $primary-dark;
      }
      > div {
        .input-prefix,
        .input-suffix,
        .clear-button {
          color: $primary-dark;
        }
      }
    }
  }

  &.error {
    > label {
      color: $error;
    }
    > div {
      border-bottom-color: $error;
      > div {
        .input-prefix,
        .input-suffix {
          color: $error;
        }
      }
    }
    &.focused,
    &.hovered {
      > div {
        .bottom-line {
          width: 100%;
          border-bottom-color: $error;
        }
      }
    }
  }
}
