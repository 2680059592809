@import 'assets/_constants';
@import 'assets/_mixins';

.funus-step-form {
  @include basic-shadow;
  background-color: $white;
  width: 100%;

  > form {
    height: 100%;
    display: flex;
    align-items: stretch;
    position: relative;

    > * {
      @include flex-column;
    }

    .form-steps {
      @include flex-column;
      justify-content: space-between;
      padding: $size-lg;
      flex: 1;
      border-right: rem(1) solid $gray-300;
      color: $gray-700;
      overflow: auto;

      .form-title {
        font-size: $font-subtitle;
        font-weight: 100;
        margin-bottom: $size-lg;
      }

      .steps-buttons {
        margin: 0 (-$size-lg);

        .funus-button {
          width: 100%;
          justify-content: flex-start;
          border-radius: 0;
          padding: $size-lg;
          border-right: $size-md solid transparent;

          &.selected:not(.disabled),
          &:hover:not(.disabled) {
            background-color: rgba($primary-light, 0.25);
            border-right-color: $primary-dark;
            color: $primary-dark;
          }

          &.selected.disabled {
            background-color: rgba($gray-700, 0.25);
            border-right-color: $gray-700;
            color: $gray-700;
          }

          &.selected.errors,
          &:hover.errors {
            background-color: rgba($error, 0.25);
            border-right-color: $error;
            color: $error;
          }

          .errors-total {
            @include flex-center;
            border-radius: 50%;
            background-color: $error;
            color: $white;
            height: calc(#{$size-lg} + #{$size-md});
            width: calc(#{$size-lg} + #{$size-md});
            margin-left: $size-md;
          }
        }
      }

      > * {
        &:not(:last-child) {
          margin-bottom: $size-lg;
        }
      }
    }

    .form-body {
      flex: 3;
      background-color: $gray-100;
      position: relative;
      overflow: hidden;

      > * {
        padding: $size-lg 0;
        @include default-transition;
        @include flex-column;
        justify-content: space-between;
        position: absolute;
        opacity: 0;
        pointer-events: none;
        right: -100%;
        left: 100%;
        top: 0;
        bottom: 0;

        .step-info {
          overflow: hidden;

          .step-title {
            padding: 0 $size-lg;
            color: $gray-700;
            font-size: $font-xl;
            font-weight: 100;
            margin-bottom: $size-lg;
          }

          .step-fields {
            padding: 0 0 0 $size-lg;
            overflow: auto;
            position: absolute;
            top: $size-xxl;
            right: 0;
            left: 0;
            bottom: calc(#{$size-xxl} + #{$size-lg});
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: flex-start;

            @media screen and (max-width: $screen-lg) {
              display: initial;
              padding: 0 $size-lg;
            }

            > *:not(fieldset) {
              margin-bottom: $size-lg;
              margin-right: calc(#{$size-lg});
              width: calc(50% - #{$size-lg});
              height: fit-content;

              @media screen and (max-width: $screen-lg) {
                margin-right: initial;
                width: 100%;
              }
            }

            > [class^='funus-'] {
              width: calc(50% - #{$size-lg});
              height: fit-content;

              @media screen and (max-width: $screen-lg) {
                width: 100%;
              }
            }

            fieldset {
              flex-direction: row;
              flex-wrap: wrap;
              width: 100%;
              margin-right: 0;
              padding-right: 0;
              &:not(:first-child) {
                margin-top: $size-lg;
              }

              legend {
                width: calc(100% - #{$size-lg});
                margin: 0;
                float: initial;

                @media screen and (max-width: $screen-lg) {
                  width: 100%;
                }
              }

              > *:not(legend) {
                width: calc(50% - #{$size-lg});
                margin-right: #{$size-lg};

                @media screen and (max-width: $screen-lg) {
                  width: 100%;
                  margin-right: 0;
                }
              }
            }
          }
        }

        .prev-button,
        .next-button {
          position: absolute;
          bottom: $size-lg;

          &.hidden {
            opacity: 0;
            pointer-events: none;
          }
        }
        .prev-button {
          left: $size-lg;
        }
        .next-button {
          right: $size-lg;
        }

        &.previous {
          right: 100%;
          left: -100%;
        }

        &.selected {
          @include delayed-transition;
          left: 0;
          right: 0;
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
}
