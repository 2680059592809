@import 'assets/_constants';
@import 'assets/_mixins';

label {
  display: block;
}

.text-error {
  color: $error;
  font-size: $font-md;
  font-weight: bold;
  @include default-transition;

  &.hidden {
    font-size: 0;
  }
}
