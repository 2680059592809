@import 'assets/_constants';
@import 'assets/_mixins';

.funus-input-content {
  @include flex-column;

  .input-container {
    display: flex;

    .funus-input {
      flex: 1;
    }

    .funus-button {
      padding: $size-md;
      height: $size-xl;
      align-self: flex-end;
    }
  }

  .content {
    @include default-transition;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: rgba($gray-900, 0.25);
    pointer-events: none;
    z-index: 1;

    &.expanded {
      opacity: 1;
      pointer-events: all;
      z-index: 2;
    }
  }
}
