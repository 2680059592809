.add-flowers-request-modal {
  .funus-select.request-type.expanded {
    margin-bottom: 5rem;
  }

  .hidden {
    display: none;
  }

  &.modal-background .modal-dialog {
    min-width: 30rem;
  }
}
