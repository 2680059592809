@import 'assets/_constants';
@import 'assets/_mixins';

.filtered-table-page {
  display: flex;
  align-items: flex-start;
  min-height: calc(100vh - #{$topbar-height});

  .table-content {
    padding: $size-xl;
    margin-left: rem(55);
    max-width: calc(100% - #{rem(55)});
    flex: 1;

    .table-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $size-xl;

      @media screen and (max-width: $screen-md) {
        align-items: flex-start;
        flex-direction: column;
      }

      h3 {
        font-weight: 200;
        color: $gray-700;
      }

      .funus-button {
        svg {
          height: rem(20);
          stroke-width: 1;
        }
      }
    }

    .lowercase {
      text-transform: inherit;
    }

    .pagination {
      padding: $size-lg;
    }
  }

  &.hidden-filters {
    .table-content {
      margin-left: 0;
      max-width: 100%;
    }
  }
}
